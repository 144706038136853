import type { QueryParamConfig } from '~/types/commons'

export const useHeadAlternateLinks = (
  {
    params,
    customPaths,
  }: {
    params?: MaybeRef<QueryParamConfig[]>
    customPaths?: MaybeRef<{ locale: string, customPath: string }[]>
  } = {},
) => {
  // unique key to dedupe objects from head
  const alternateLinkKey = (s: string) => `rel-alternate-${s}`

  const { locales } = useI18n()
  const route = useRoute()

  const availableLocalesMap = computed(() => {
    type LinkMapEntry = { locale: AvailableLocaleCodes, url?: URL }
    const linksMap = new Map<string, LinkMapEntry>()

    const customPathsValue = toValue(customPaths)
    locales.value.forEach(({ yscCode: locale, domain }) => {
      const key = alternateLinkKey(locale)
      const value: LinkMapEntry = { locale }
      if (domain) {
        const origin = domain.match(new RegExp('^https?://')) ? domain : `https://${domain}`

        if (customPathsValue) {
          const customPathConfig = customPathsValue.find(({ locale: customPathLocale }) => customPathLocale === locale)
          if (customPathConfig) {
            value.url = new URL(customPathConfig.customPath, origin)
          }
        }
        else {
          value.url = new URL(route.fullPath, origin)
        }
      }

      linksMap.set(key, value)
    })

    return Array.from(linksMap.entries())
  })

  const alternateLinks = computed(() => {
    const queryParamsValue = toValue(params)

    return availableLocalesMap.value.map(([key, { url, locale }]) => {
      if (!url) {
        // remove link tag from head
        return { key }
      }

      const { href } = queryParamsValue?.length ? getURLWithQueryParams({ url, params: queryParamsValue }) : url

      return {
        key,
        rel: 'alternate',
        hreflang: locale,
        href,
      }
    })
  })

  const { format } = getOpenGraphLocaleFormatter()
  const ogLocalesAlternate = computed(() => {
    return availableLocalesMap.value
      .filter(([_key, { url }]) => !!url)
      .map(([_key, { locale }]) => getMetaProperty({ property: 'og:locale:alternate', content: format(locale) }))
  })

  useHead({
    meta: ogLocalesAlternate,
    link: alternateLinks,
  })
}
